<script setup>
	// Libraries
	import { storeToRefs } from 'pinia';

	// Stores
	import { useHoursStore } from '@/stores/hours';

	// Store data
	const { hours, special, message } = storeToRefs(useHoursStore());

	// Props
	const { style } = defineProps({
		style: {
			type: String,
			default: 'base',
		},
		showMessages: {
			type: Boolean,
			default: false,
		},
		showSpecialClosures: {
			type: Boolean,
			default: true,
		},
	});
</script>

<template>
	<div :class="`fragment-center-hours style-${style}`">
		<ul class="base-hours">
			<li v-for="set in hours" :key="JSON.stringify(set) + '-hours-set'" class="item">
				<strong v-html="set.days" />
				<p v-html="set.hours" />
			</li>
		</ul>
		<div class="hours-message" v-if="showMessages && message.show">
			<strong class="h6">{{ message.title || 'Holiday Hours' }}</strong>
			<MessHtml :html="message.message" class="fs-14" v-if="message.message" />
		</div>
		<div
			v-if="special.length && showSpecialClosures"
			class="special-closures"
			:class="`${showMessages ? 'has-messages' : ''}`"
		>
			<strong class="h5">special closures</strong>
			<ul>
				<li v-for="closure in special">
					<span class="hours">
						<p class="date" v-html="closure.dateString" />
						<template v-if="closure.centerClosed"><p>Closed all day</p></template>
						<template v-else>
							<span>Closed <span v-html="closure.open" /> - <span v-html="closure.closed" /></span>
						</template>
					</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<style lang="scss">
	.fragment-center-hours {
		font-size: calc(16 / var(--base-fs) * 1rem);

		@media (min-width: $tablet) {
			font-size: calc(14 / var(--base-fs) * 1rem);
		}

		*,
		* *,
		* * * {
			font-size: calc(16 / var(--base-fs) * 1rem);

			@media (min-width: $tablet) {
				font-size: calc(14 / var(--base-fs) * 1rem);
			}
		}

		ul {
			display: flex;
			align-items: center;
			gap: 1rem;
			flex-wrap: wrap;

			li {
				font-size: inherit;
			}
		}

		strong {
			font-weight: 700;
			text-transform: uppercase;
		}

		.hours-message,
		.special-closures {
			margin-top: 1rem;

			.message {
				text-transform: none;
				margin-left: calc(1ch + 0.25rem);
			}

			ul {
				flex-direction: column;
				align-items: flex-start;
				// margin-left: 0.25rem;
				margin-top: 0.25rem;
				gap: 0;
				li {
					flex-direction: column;
					align-items: flex-start;

					.hours {
						display: flex;
						align-items: center;
						gap: 0.5rem;
					}
				}
			}

			&.has-messages {
				ul {
					li {
						+ li {
							margin-top: 0.5rem;
						}
					}
				}
			}
		}

		&.style-base {
			font-size: 14px;
			ul.base-hours {
				li {
					display: flex;
					gap: 0.5rem;
					align-items: center;
					justify-content: space-between;
				}
			}
		}

		&.style-child-block {
			font-size: 16px;

			ul.base-hours {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: -25px;
				gap: 0;

				li.item {
					margin-bottom: 25px;
					width: 50%;

					&:nth-of-type(odd) {
						width: calc(50% - 15px);
						margin-right: 15px;
					}
				}
			}

			.special-closures {
				font-size: calc(16 / var(--base-fs) * 1rem);

				@media (min-width: $tablet) {
					font-size: calc(14 / var(--base-fs) * 1rem);
				}
			}
		}
	}
</style>
